import { $t } from 'o365-utils';
export default class SearchFunction{
    private _currentOption:string = 'contains_all';
    fnc:string;
    _label:any|null = null;
    operator:string= "search_fnc";
    column:string;
    _value:string|null = null;
    _searchString:string|null = null;
    _currentOptionIndex:number = 1;
  
    options:Array<any> = [
        {name:'contains', title:$t('Contains')},
        {name:'contains_all', title: $t('Contains All')},
        {name:'contains_any', title: $t('Contains Any')},
    ];

    set currentOption(pOption: string){
        this._currentOption = pOption;
        this.setFullTextSearchString(this.value);
        this._currentOptionIndex = this.options.findIndex(x=>x.name == this._currentOption)


    }

    get label(){
        return this._label;
    }
    get searchForLabel(){
        if(this._label)
            return $t('Search for') + ' ' + this._label;

        return $t('Search')+"..."
    }

    set value(pString:string|null){

        this._value = pString;
        if(!pString) this.searchString = null;
        if(pString) this.setFullTextSearchString(this._value);
    }

    get currentOptionIndex(){
        return this._currentOptionIndex;
    }

    get value(){
       
        return this._value?this._value:null;
    }

    get sarchStringForSave(){
        return `[_FULL_TEXT_${this.currentOption}] = '${this.value}'`;
    }

    set searchString(pString:string|null){
        this._searchString = pString;
    }

    get searchString(){
       
        return this._searchString?this._searchString:null;
    }

    get isSet(){
        return this.value?true:false;
    }

    get currentOption(){
        return this._currentOption;
    }

    get prettyString(){
        return `${this.currentoptionTitle} (${this.value})`;
    }

    get currentoptionTitle(){
        return this.options.find(x=>x.name == this.currentOption)?.title
    }

    constructor(pOptions:any){
        this.fnc = pOptions.searchFnc;
        this._label = pOptions.label;
        this.column = pOptions.column;
    }

    getFilterString(pValue:string){
        return this.getFullTextSearchString(pValue);
    }

    getPrettyString(pValue:string){
        return `${this.currentoptionTitle} (${pValue})`;

    }

    escapeFullTextString(str:string) {
        return `"${str.replaceAll('"', '""')}*"`
    }
    escapeTextString(str:string) {
        return str.replaceAll('"', '""');
    }

    splitAndEscapeFullTextString(str:string| string[], keyword:string) {
    if (Array.isArray(str)) {
        str = str.join(' ');  
    }

    if (typeof str !== 'string') {
        return
    }

    const words = str.split(' ').filter(s => s);
    //if(words.length == 1){
      //  return this.escapeTextString(words[0])+"*";
   // }
    return words.map(this.escapeFullTextString).join(` ${keyword} `);
    }

    getFullTextSearchString(searchString:string){
        if ( this._currentOption == "contains") {
            return this.escapeFullTextString(searchString);
        } else if ( this._currentOption == "contains_all") {
            return this.splitAndEscapeFullTextString(searchString, 'AND');
        } else if ( this._currentOption == "contains_any") {
            return this.splitAndEscapeFullTextString(searchString, 'OR');
        } else {
            return searchString;
        }
    }

    setFullTextSearchString(searchString:string|null){
        if(!searchString){
            this.value = null;
            return;
        }
        this.searchString = this.getFullTextSearchString(searchString);
    }

    setFromUrl(pOptions:Array<any>){
        this.currentOption = this.options[pOptions[1]]?.name;
        this.value = pOptions[2];
    }
    setFromSavedString(pOptions:any){
        this.currentOption = pOptions.column.replace("_FULL_TEXT_","");
        this.value = pOptions.value;
    }

    setFromExternal(pOptions:any){
        if(pOptions.constructor == Array){
            this.setFromUrl(pOptions);
            return
        }

        this.setFromSavedString(pOptions);
    }

    

    
}